import { useEffect } from 'react';
import './style.css';

function App() {

  useEffect(() => {
    var audioElement = document.getElementById("audioElement");
    var playButton = document.getElementById("playButton");

    playButton.addEventListener("click", function () {
      if (audioElement.paused) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    });

    /////////////////////////////

    const items = document.querySelectorAll('.item1, .item2, .item3, .item4');

    let activeDescriptionItem = null;

    items.forEach((item) => {
      item.addEventListener('mouseenter', function () {
        const itemName = item.getAttribute('data-name');
        const correspondingDescriptionItem = document.querySelector(`.description-items[data-name="${itemName}"]`);

        if (activeDescriptionItem !== correspondingDescriptionItem) {
          if (activeDescriptionItem) {
            activeDescriptionItem.style.display = 'none';
          }
          correspondingDescriptionItem.style.display = 'block';
          activeDescriptionItem = correspondingDescriptionItem;
        }
      });


      item.addEventListener('mouseleave', function () {
        const itemName = item.getAttribute('data-name');
        const correspondingDescriptionItem = document.querySelector(`.description-items[data-name="${itemName}"]`);

        if (activeDescriptionItem !== correspondingDescriptionItem) {
          correspondingDescriptionItem.style.display = 'none';
        }
      });

      item.addEventListener('click', function (event) {
        event.stopPropagation(); // Stop the click event from propagating to the parent elements
        const itemName = item.getAttribute('data-name');
        const correspondingDescriptionItem = document.querySelector(`.description-items[data-name="${itemName}"]`);

        if (activeDescriptionItem !== correspondingDescriptionItem) {
          if (activeDescriptionItem) {
            activeDescriptionItem.style.display = 'none';
          }
          correspondingDescriptionItem.style.display = 'block';
          activeDescriptionItem = correspondingDescriptionItem;
        } else {
          correspondingDescriptionItem.style.display = 'none';
          activeDescriptionItem = null;
        }
      });
    });
  }, 
  []);

  return (
    <>
      <div className="audio">
        <audio id="audioElement" hidden>
          <source src="audios/Helen.mp3" type="audio/mp3" />
        </audio>
      </div>
      <div className="Titulo" id="playButton">
        <p>Summer Season</p>
      </div>

      <div className="follow">
        <p>Follow us</p>
      </div>
      <div className="container-media">
        <a href="https://www.instagram.com/summerseason.rocks/" target="_blank" rel="noreferrer">
          <img src="images/social/ig_wicon.png" alt="Instagram" />
        </a>
        <a href="https://www.youtube.com/@SummerSeasonband" target="_blank" rel="noreferrer">
          <img src="images/social/yb_wlogo.png" alt="YouTube" />
        </a>
        {/* <a href="https://open.spotify.com/intl-es/artist/0MHKM4YOtcvGPhncpXsPMF" target="_blank" rel="noreferrer">
          <img src="images/social/spotify_wlogo.png" alt="Spotify" />
        </a> */}
        <a href="https://soundcloud.com/mauriciodmb" target="_blank" rel="noreferrer">
          <img src="images/social/sc_wlogo.png" alt="SoundCloud" />
        </a>
      </div>
      <div className="footer">
        <p>Haciendo ruido since 2010 <br /> Rionegro | Colombia <br /> Copyright © </p>
      </div>

      <header className="header">
        <div className="container-menu">
          <div className="menu">
            <ul>
              <li><a href="#">HOME</a></li>
              <li><a href="#music">MUSIC</a></li>
              <li><a href="#galery">GALERY</a></li>
              <li><a href="#merch"><s>MERCH</s></a></li>
            </ul>
          </div>
        </div>
      </header>

      <div className="ancla-album" id="music">
        <p>Ancla</p>
      </div>
      <div className="container-album" >
        <div className="music">
          <p> ALBUMS </p>
        </div>
        <div className="items">
          <div className="item1" data-name="p-1">
            <img src="images/album/Open.jpg" alt="Portada 1" />
            <p><b>Open Season</b> <br /> 2011</p>
          </div>
          <div className="item2" data-name="p-2">
            <img src="images/album/Titulos.jpg" alt="Titulos album cover" />
            <p><b>Titulos</b> <br /> 2012 </p>
          </div>
          <div className="item3" data-name="p-3">
            <img src="images/album/Volver.jpg" alt="Volver a sonar album cover" />
            <p><b>Volver a sonar</b> <br /> 2017 </p>
          </div>
          <div className="item4" data-name="p-4">
            <img src="images/album/Latente.jpg" alt="Latente album cover" />
            <p><b>Latente</b> <br /> 2020 </p>
          </div>
        </div>
        <div className="description-items" data-name="p-1">
          <div className="titulo-portada1">
            <p>Open Season</p>
          </div>
          <div className="canciones">
            <p>  <b>1.</b> Open season</p>
            <p>  <b>2.</b> Un segundo más</p>
            <p>  <b>3.</b> Nebulas</p>
            <p>  <b>4.</b> Señor miseria</p>
            <p>  <b>5.</b> Ana la gusana</p>
            <p>  <b>6.</b> El reflejo</p>
            <p>  <b>7.</b> Charles es el hombre de gafas</p>
            <p>  <b>8.</b> El resto son detalles</p>
            <p>  <b>9.</b> Grapas</p>
            <p>  <b>10.</b> En mi y en nadie</p>
          </div>
          <iframe className="video" width="570" height="360" src="https://www.youtube.com/embed/Fs80Cy_S7Sc" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        <div className="description-items" data-name="p-2">
          <div className="titulo-portada2">
            <p>Títulos</p>
          </div>
          <div className="canciones">
            <p>  <b>1.</b> Titulos</p>
            <p>  <b>2.</b> Para siempre</p>
            <p>  <b>3.</b> Intocable</p>
            <p>  <b>4.</b> Helen</p>
            <p>  <b>5.</b> Anestesia (Anastasia)</p>
            <p>  <b>6.</b> Cartas</p>
            <p>  <b>7.</b> Sin pensar en nada</p>
            <p>  <b>8.</b> Magenta</p>
            <p>  <b>9.</b> Como nunca</p>
            <p>  <b>10.</b> Hologram ft Topo</p>
          </div>
          <iframe className="video" width="570" height="360" src="https://www.youtube.com/embed/H6hIl7Ya8BE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        <div className="description-items" data-name="p-3">
          <div className="titulo-portada3">
            <p>Volver a sonar</p>
          </div>
          <div className="canciones">
            <p>  <b>1.</b> Elefantes amarillos</p>
            <p>  <b>2.</b> Intenté</p>
            <p>  <b>3.</b> Y no sé</p>
            <p>  <b>4.</b> La formula de la felicidad</p>
            <p>  <b>5.</b> Obstinado</p>
            <p>  <b>6.</b> Mentir por omisión</p>
            <p>  <b>7.</b> Antipatica</p>
            <p>  <b>8.</b> Largarta</p>
            <p>  <b>9.</b> Mauren</p>
          </div>
          <iframe className="video" width="570" height="360" src="https://www.youtube.com/embed/SauSWF1swHo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        <div className="description-items" data-name="p-4">
          <div className="titulo-portada4">
            <p>Latente</p>
          </div>
          <div className="canciones">
            <p>  <b>1.</b> Asara</p>
            <p>  <b>2.</b> Latente</p>
            <p>  <b>3.</b> No quepo en el cielo</p>
            <p>  <b>4.</b> Desafortunada</p>
            <p>  <b>5.</b> Caminar contigo en el amanecer</p>
            <p>  <b>6.</b> No</p>
            <p>  <b>7.</b> Estampa</p>
            <p>  <b>8.</b> Estupido</p>
            <p>  <b>9.</b> Fantasmas</p>
          </div>
          <iframe className="video" width="570" height="360" src="https://www.youtube.com/embed/WAYCCmCkGJc" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>

      <div className="image-gallery" id="galery">
        <img src="images/img4.jpg" alt="Sunset in the sea" />
        <img src="images/img2.jpg" alt="Band playing at the beach" />
        <img src="images/img1.jpg" alt="Members practiving skating" />
        <img src="images/img3.jpg" alt="Band playing live at stage" />
        <img src="images/img5.jpg" alt="Band members" />
        <img src="images/img6.jpg" alt="Band playing" />
      </div>


      <div className="container-media-footer">
        <a href="https://www.instagram.com/summerseason.rocks/" target="_blank" rel="noreferrer">
          <img src="images/social/ig_wicon.png" alt="Instagram" />
        </a>
        <a href="https://www.youtube.com/@SummerSeasonband" target="_blank" rel="noreferrer">
          <img src="images/social/yb_wlogo.png" alt="YouTube" />
        </a>
        {/* <a href="https://open.spotify.com/intl-es/artist/0MHKM4YOtcvGPhncpXsPMF" target="_blank" rel="noreferrer">
          <img src="images/social/spotify_wlogo.png" alt="Spotify" />
        </a> */}
        <a href="https://soundcloud.com/mauriciodmb" target="_blank" rel="noreferrer">
          <img src="images/social/sc_wlogo.png" alt="SoundCloud" />
        </a>
      </div>
      <div className="footer">
        <p>Copyright © </p>
      </div>
    </>
  );
}

export default App;
